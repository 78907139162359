@import '../../../base/assets/scss/_base/config';
@import '../../../base/assets/scss/_base/mixins';

.ie-11 {
  .m-AolFooter {
    .logo-wrap {
      min-height: 65px;
    }

    .links-foot{
      width: 70%;
    }
    .social-foot{
      width: 30%;

      .item{
        width: 50px;
      }
    }
  }
}
.m-AolFooter {
  padding: 0 20px;
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    &:not(.cell){
      margin: 0 auto 36px;
    }
    max-width: 1400px;
    width: 100%;
    position: relative;

    .logo-wrap {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 32px;
      order: 1;
      padding: 15px 0 12px;
      width: 100%;
      border-bottom: 1px solid $dirty_seagull;

      .logo {
        svg {
          fill: #000100;
          height: 38px;
          width: 94px;
          margin-top: 5px;
        }
      }

      @media screen and (max-width: 900px) {
        .logo {
          svg {
            width: 80px;
          }
        }
      }

    }

    .footer-links {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      order: 2;
    }
    .itemList {
      display: flex;
      flex: 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0;
      order: 2;
      padding: 0;
      text-align: left;
      padding-bottom: 24px;


      .item {
        font-family: var(--font-family-base);
        font-size: 14px;
        line-height: 1.25;
        font-weight: 300;
        list-style: none;
        margin: 0 0 12px 0;
        padding-right: 20px;
        color: $dolphin;

        .itemPrefix {
          color: $a11y-dark-gray;
          display: inline-block;
          padding: 6px 15px 6px 6px;
        }

        .itemLink {
          color: $dolphin;
          cursor: pointer;
          display: inline-block;
          text-decoration: none;
          text-transform: capitalize;

          &:hover {
            color: $blue;
          }
        }

        .itemImage {
          height: 16px;
          width: auto;

          img {
            height: 16px;
            width: auto;
            padding-left: 3px;
            margin-top: 1px;
          }
        }

        .itemSuffix {
          color: $blue;
          display: inline-block;
          font-weight: 700;
          padding: 6px 6px 6px 0;
        }

        .itemSuffix::before {
          content: '(';
        }

        .itemSuffix::after {
          content: ')';
        }

        &.rowBreak {
          width: 100%;
          padding: 0;
          margin: 0;
        }
      }
    }

    .iconList {
      display: flex;
      flex-direction: row;
      justify-content: center;
      list-style: none;
      margin-bottom: 16px;
      margin-top: 0;
      padding: 0;
      width: 100%;

      .item {
        & + .item {
          padding: 0 0 0 24px;
        }

        .link {
          color: $dolphin;

          .icon {
            fill: $dolphin;
            height: 24px;
          }

          &:hover{
            color: $dory;

            .icon {
              fill: $dory;
            }
          }
        }
      }

      &.appStoreLinks {
        a{
          width: 100%;
          display: block;
        }
        img{
          width: 100%;
        }
        .item {
          width: 110px;
          box-sizing: content-box;
          padding: 0;

          &--google{
            width: 138px;
            margin-top: -8px;

            &_de{
              width: 130px;
              margin-top: -5px;
              padding-right: 12px;
            }
          }

          &--apple{
            padding-left: 0px;
          }
        }

      }
    }

    .copyright {
      font-family: var(--font-family-base);
      bottom: 0;
      color: $dolphin;
      font-size: 12px;
      order: 10;
      right: 0;
      text-align: left;
      font-weight: 300;
      position: absolute;
      left: 0;
    }
  }

  &--grid{
    padding: 0;
    width: unset;

    .container{
      max-width: none;
    }
  }
  &.video {
    background-color: #000;

    .container {
      padding-top: 5px;

      .footer-divider {
        border: 0;
      }

      .logo-wrap {
        border-bottom-color: $bob;

        .title{
          display: block;
        }

        .logo svg {
          fill: $grey-dark;
        }
      }

      .body .logo svg {
        fill: #333;
      }
    }
  }

  @media screen and (max-width: 850px) {
    .footer-links {
      .links-foot {
        .itemList {
          .item {
            &.rowBreak{
              display: none;
            }
          }

        }
      }
    }
  }
  @media screen and (max-width: 753px) {
    .container {
      margin-bottom: 15px;
      .footer-divider{
        border-top: 1px solid $dirty_seagull;
        margin-bottom: 20px;
      }

      .itemList{
        padding-bottom: 12px;
      }
      .footer-links {
        flex-flow: column;
        .links-foot {
          .itemList {
            justify-content: center;

            .item {
              margin-bottom: 20px;
              padding-right: 15px;
            }

          }
        }
      }

      .logo-wrap {
        order: 4;
        border-bottom: none;
        border-top: 1px solid $dirty_seagull;
        margin-bottom: 0;
        padding: 15px 0 0;

        .logo svg{
          height: 18px;
          width: 41px;
        }

        .title{
          padding: 3px 0px 0px 12px;
          font-size: 14px;
        }
      }

      .copyright{
        left: auto;
        right: 0;
      }
    }

    &.video .container {
      border-top: 1px solid $bob;

      .itemList.contentinfo {
        border: 0;
      }
    }
  }
}

@media print {
  .m-AolFooter {
    display: none;
  }
}
